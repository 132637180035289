<template>
  <div class="collection">
    <div class="head" @touchmove.prevent>
      <img
        src="../../assets/img/commoditySpike/back.png"
        alt=""
        @click="$goBack()"
      />
      <div class="title">我的收藏</div>
    </div>
    <div class="main">
      <van-tabs
        @touchmove.prevent
        v-model="active"
        line-width=".266667rem"
        line-height="0.08rem"
        color="#FF5200"
        animated
        title-active-color="#FF5200"
        title-inactive-color="#333"
        @change="changeIndex"
      >
        <van-tab
          title-style="font-size:.426667rem;overflow:none; "
          v-for="(info, index) in list"
          :key="index"
        >
          <!-- :disabled="index === 1 ? true : false" -->
          <template #title>
            <div>
              {{ info.title }}
            </div>
          </template>
        </van-tab>
      </van-tabs>
      <div class="content">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="20"
          ref="list"
        >
          <div class="warp">
            <img
              v-if="isShow"
              class="empty"
              src="../../assets/img/empty_shoucang.png"
            />

            <div v-if="active == 0" class="list-box">
              <div
                class="item"
                v-for="(item, num) in collect"
                :key="item.goodsSpu.id"
                @click="goMall(item)"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id, num)"
                    />
                  </template>

                  <div
                    style="
                      heigth: 100%;
                      display: flex;
                      padding: 0.26rem;
                      overflow: hidden;
                    "
                  >
                    <img
                      class="good-img"
                      referrerpolicy="no-referrer"
                      :src="item.goodsSpu.picUrls[0]"
                    />
                    <div class="item-box">
                      <div class="name">
                        <img
                          v-if="item.platformId == '1'"
                          class="label1"
                          referrerpolicy="no-referrer"
                          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng059202cad8b3c872aaf489d1616749bd56f7465f66e8fc4b6af166923e51a1a0"
                        />
                        <img
                          v-if="item.platformId == '2'"
                          class="label1"
                          referrerpolicy="no-referrer"
                          src="../../assets/roomImg/pdd.png"
                        />
                        <img
                          v-if="item.platformId == '3'"
                          class="label1"
                          referrerpolicy="no-referrer"
                          src="../../assets/roomImg/jingdong.png"
                        />

                        {{
                          item.goodsSpu.name.length > 25
                            ? item.goodsSpu.name.slice(0, 25) + "..."
                            : item.goodsSpu.name
                        }}
                      </div>
                      <div class="price">
                        <span>￥</span>{{ item.goodsSpu.priceDown }}
                        <span
                          style="color: #999; font-size: 12px"
                          v-if="item.platformId == null"
                        >
                          已售{{
                            item.goodsSpu.saleNum > 10000
                              ? "1万+"
                              : item.goodsSpu.saleNum
                          }}</span
                        >
                      </div>
                      <div
                        class="shop van-multi-ellipsis--l2"
                        v-if="item.goodsSpu.spName"
                      >
                        {{ item.goodsSpu.spName }}
                      </div>
                      <div class="oraginprice">
                        <div v-if="item.goodsSpu.priceUp && item.platformId">
                          原价<span>￥{{ item.goodsSpu.priceUp }}</span>
                        </div>
                        <div v-if="item.goodsSpu.saleNum && item.platformId">
                          已售{{
                            item.goodsSpu.saleNum > 10000
                              ? "1万+"
                              : item.goodsSpu.saleNum
                          }}
                        </div>
                      </div>
                      <div class="coupons">
                        <span
                          class="coupons-itme"
                          v-if="item.goodsSpu.couponMoney"
                        >
                          <div>券￥{{ item.goodsSpu.couponMoney }}</div></span
                        >
                        <span v-if="item.goodsSpu.shareMoney">
                          <div>分享赚{{ item.goodsSpu.shareMoney }}</div></span
                        >
                      </div>
                      <img
                        class="lose"
                        v-if="item.collectStatus === '0'"
                        src="../../assets/img/lose.png"
                        alt=""
                      />
                    </div>
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div v-if="list[active].index == 7">
              <div
                class="item1"
                v-for="(item, index) in collect"
                :key="item.id"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelCollection(item, index, '2')"
                    />
                  </template>
                  <div
                    style="
                      heigth: 100%;
                      display: flex;
                      padding: 0.26rem;
                      overflow: hidden;
                    "
                    @click="goFood(item.id, true, item.shopId)"
                  >
                    <div class="item1-box">
                      <img
                        class="img1"
                        referrerpolicy="no-referrer"
                        :src="item.images[0]"
                      />
                      <div class="right">
                        <div class="title">{{ item.name }}</div>
                        <div class="sell-num">月售{{ item.mouthSell }}份</div>
                        <div class="price">
                          <div>
                            <div>￥</div>
                            <span>{{ item.discountPrice }}</span>
                          </div>
                          <span>￥{{ item.price }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- <img class="lose" src="../../assets/img/lose.png" alt="" /> -->
                  </div>
                  <div class="name">
                    <img
                      class="label2"
                      referrerpolicy="no-referrer"
                      style="border-radius: 20%"
                      :src="item.shopLogoUrl"
                    />
                    <span> {{ item.shopName }}</span>
                    <!-- 店铺名 -->
                  </div>
                </van-swipe-cell>
              </div>
            </div>

            <div v-else-if="active === 1">
              <div
                class="item2"
                v-for="item in collect"
                :key="item.id"
                @click="jump('1', item.positionInfoVo.id)"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id)"
                    />
                  </template>

                  <div
                    style="
                      heigth: 100%;
                      padding: 0.26rem;
                      overflow: hidden;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <div class="position">
                      <div class="name">{{ item.positionInfoVo.name }}</div>
                      <div class="welfare">
                        {{ item.positionInfoVo.salary }}
                      </div>
                    </div>
                    <div class="ask" style="flex: 1">
                      <div
                        class="lable"
                        v-for="(
                          list, index
                        ) in item.positionInfoVo.keyword.split('，')"
                        :key="index"
                      >
                        {{ list }}
                      </div>
                    </div>
                    <div class="company-info">
                      <div>{{ item.positionInfoVo.companyName }}</div>
                      <div v-if="item.positionInfoVo.staffNum">
                        <p></p>
                        {{ item.positionInfoVo.staffNum }}人
                      </div>
                      <div v-if="item.positionInfoVo.cityCode">
                        <p></p>
                        {{ item.positionInfoVo.cityCode }}
                      </div>
                      <div v-if="item.positionInfoVo.areaCode">
                        <p></p>
                        {{ item.positionInfoVo.areaCode }}
                      </div>
                    </div>
                    <img
                      v-if="item.collectStatus === '0'"
                      class="lose"
                      src="../../assets/img/lose.png"
                      alt=""
                    />
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div
              v-if="active === 2 && collect.length != 0 && collect[0].map"
              class="list-box"
            >
              <div
                class="item3"
                v-for="(item, num) in collect"
                :key="item.id"
                @click="jump('0', item.map.candidateInfo.id)"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id, num)"
                    />
                  </template>
                  <div
                    style="
                      heigth: 100%;
                      display: flex;
                      padding: 0.26rem;
                      overflow: hidden;
                      box-sizing: border-box;
                    "
                  >
                    <img :src="item.map.candidateInfo.headUrl" alt="" />

                    <div class="persion-info" v-if="item">
                      <div class="name" v-if="item.map.candidateInfo.name">
                        {{ item.map.candidateInfo.name }}
                      </div>
                      <div class="lables">
                        <div class="lable" v-if="item.map.candidateInfo.age">
                          {{ item.map.candidateInfo.age }}岁
                        </div>
                        <div
                          class="lable"
                          v-if="item.map.candidateInfo.workage"
                        >
                          工作{{ item.map.candidateInfo.workage }}年
                        </div>
                        <div
                          class="lable"
                          v-if="item.map.educationList[0].education"
                        >
                          {{ item.map.educationList[0].education }}
                        </div>
                        <div
                          class="lable"
                          v-if="item.map.intentionList[0].salary"
                        >
                          {{ item.map.intentionList[0].salary }}
                        </div>
                      </div>
                      <div class="bottom">
                        <div>{{ item.map.jobList[0].componyName }}</div>
                        <div>
                          {{ item.map.jobList[0].workTime }}年
                          <!-- {{(new Date(item.map.jobList[0].workBeginTime.replace(/-/ig,'/')).getTime()-new Date(item.map.jobList[0].workEndTime.replace(/-/ig,'/')).getTime())%(1000*60*60*24*365)>=0.5?'半':''}} -->
                        </div>
                      </div>
                    </div>
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div
              v-if="collect.length && collect[0].type === '4'"
              class="list-box"
            >
              <div
                class="item4"
                @click="
                  goHouse(item.houseSource.id, item.houseSource.sellStatus)
                "
                v-for="(item, num) in collect"
                :key="item.id"
              >
                <van-swipe-cell v-if="item.houseSource">
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id, num)"
                    />
                  </template>
                  <div
                    style="
                      heigth: 100%;
                      display: flex;
                      padding: 0.26rem;
                      overflow: hidden;
                      box-sizing: border-box;
                    "
                  >
                    <img :src="item.houseSource.pics" alt="" />
                    <div class="house-box">
                      <div class="desc" v-if="item.houseSource.houseName">
                        {{ item.houseSource.houseName }}
                      </div>

                      <div class="lables">
                        <div class="lable" v-if="item.houseSource.subwayLine">
                          {{ item.houseSource.subwayLine }}
                        </div>
                        <div
                          class="lable"
                          v-if="item.houseSource.communityName"
                        >
                          {{ item.houseSource.communityName }}
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="price">
                          {{ item.houseSource.quotedPrice }}万
                        </div>

                        <div class="labels">
                          <div class="lable">
                            {{
                              item.houseSource.bedroom +
                              "室" +
                              item.houseSource.parlor +
                              "厅" +
                              item.houseSource.bathroom +
                              "卫"
                            }}
                          </div>
                          <p></p>

                          <div class="lable lable1" style="display: block">
                            {{ item.houseSource.area }}m <span>2</span>
                          </div>
                        </div>
                      </div>
                      <img
                        v-if="item.houseSource.sellStatus === '0'"
                        class="lose"
                        src="../../assets/img/lose.png"
                      />
                    </div>
                  </div>
                </van-swipe-cell>
              </div>
            </div>

            <div
              v-else-if="
                collect.length > 0 && collect[0].type === '3' && active === 4
              "
              class="list-box"
            >
              <div
                class="item5"
                @click="goCar(item.vehicle.id, item.vehicle.salesStatus == '0')"
                v-for="(item, num) in collect"
                :key="item.id"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id, num)"
                    />
                  </template>
                  <div
                    style="
                      heigth: 100%;
                      display: flex;
                      padding: 0.26rem;
                      overflow: hidden;
                      box-sizing: border-box;
                    "
                  >
                    <img :src="item.vehicle.imgUrls[0]" alt="" />
                    <div class="house-box">
                      <div class="desc">
                        {{
                          item.vehicle.name.length > 25
                            ? item.vehicle.name.slice(0, 25) + "..."
                            : item.vehicle.name
                        }}
                      </div>
                      <div class="lables">
                        <div class="lable">
                          {{ item.vehicle.licensingTime.slice(0, 4) }}年上牌
                        </div>
                        <div class="lable">
                          {{ item.vehicle.mileage }}万公里
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="price">
                          {{ item.vehicle.listedPrice }}万
                        </div>
                      </div>
                      <img
                        class="lose"
                        v-if="item.vehicle.salesStatus == 0"
                        src="../../assets/img/lose.png"
                        alt=""
                      />
                    </div>
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div v-else-if="active == 6">
              <div class="item6" v-for="(item, num) in collect" :key="item.id">
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelColl(item.id, num)"
                    />
                  </template>

                  <div style="heigth: 100%; display: flex; position: relative">
                    <ul class="shop-list">
                      <!-- item.storeBasic==null &&(item.collectStatus === '0'||(item.type=='2'&& -->
                      <!-- v-if=" item.shopInfo.operationStatus !=1" -->
                      <img
                        class="close-shop"
                        src="../../assets/img/closeShop.png"
                        alt=""
                      />
                      <!-- v-if="item.storeBasic==null && item.shopInfo.agentPerformanceCode=='0'" -->

                      <li
                        v-if="
                          item.storeBasic == null &&
                          item.shopInfo.agentPerformanceCode == '0'
                        "
                        class="list-item"
                        @click="
                          goShop(
                            '/detailsPage',
                            item.shopInfo.id,
                            item.shopInfo.operationStatus
                          )
                        "
                      >
                        <img
                          class="pic2"
                          referrerpolicy="no-referrer"
                          :src="item.shopInfo.imgUrl"
                        />

                        <div class="right">
                          <div class="name">{{ item.shopInfo.name }}</div>

                          <div class="middle">
                            <div class="desc">
                              <p>{{ item.shopInfo.detail }}</p>
                            </div>

                            <div v-if="item.shopInfo.follow">
                              {{
                                item.shopInfo.follow > 10000
                                  ? parseInt(item.shopInfo.follow) + "万"
                                  : item.shopInfo.follow
                              }}人关注
                            </div>

                            <div v-else>0人关注</div>
                          </div>
                          <div class="img-list">
                            <img
                              v-for="img in item.shopInfo.listGoodsSpu"
                              :key="img.id"
                              :src="img.picUrls[0]"
                              alt=""
                            />
                          </div>
                        </div>
                      </li>

                      <!-- 创客店铺 -->

                      <li
                        class="list-item"
                        @click="
                          goShop(
                            '/makerStoreDetail',
                            item.storeBasic.storeProductInfos[0].shopId,
                            item.shopInfo.operationStatus
                          )
                        "
                        v-if="item.storeBasic != null"
                      >
                        <img
                          class="pic2"
                          referrerpolicy="no-referrer"
                          :src="item.storeBasic.logoUrl"
                        />

                        <div class="right">
                          <div class="name">
                            {{ item.storeBasic.storeName }}(创客店铺)
                          </div>

                          <div class="middle">
                            <div class="desc">
                              <p>{{ item.storeBasic.storeDescribe }}</p>
                            </div>

                            <!-- <div v-if="item.shopInfo.bond"> {{ item.shopInfo.bond > 10000 ? parseInt(item.shopInfo.bond) + "万": item.shopInfo.bond  }}人关注 </div> -->

                            <!-- <div v-else>0人关注</div> -->
                          </div>
                          <div class="img-list">
                            <!-- <img v-for="img in item.storeBasic.pics"  :key="img" :src="img"  alt="" /> -->
                          </div>
                        </div>
                      </li>

                      <li
                        class="list-item2"
                        v-if="
                          item.storeBasic == null &&
                          item.shopInfo.agentPerformanceCode == '6' &&
                          active == 6
                        "
                        @click="goHotel(item.shopInfo.id)"
                      >
                        <img class="pic2" :src="item.shopInfo.imgUrl" alt="" />

                        <div class="right">
                          <div class="name">
                            {{ item.shopInfo.name }}
                            <span v-if="item.shopInfo.hotelLevel === '0'"
                              >经济型</span
                            >
                            <span v-if="item.shopInfo.hotelLevel === '1'"
                              >舒适型</span
                            >
                            <span v-if="item.shopInfo.hotelLevel === '2'"
                              >高档酒店</span
                            >
                            <span v-if="item.shopInfo.hotelLevel === '3'"
                              >豪华酒店</span
                            >
                          </div>
                          <!-- <div class="location">正大广场 · 明珠广场</div> -->

                          <div class="bottom">
                            <div class="price">
                              <span v-if="item.marketPrice"
                                >￥{{ item.marketPrice }}</span
                              >
                              <div>
                                ￥<span>{{ item.price }}</span>
                              </div>
                              起
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- $router.push({ path:'lifeDetails', query:{Id :item.shopInfo.id}}) -->
                      <li
                        class="list-item3"
                        v-if="
                          item.storeBasic == null &&
                          item.shopInfo.agentPerformanceCode == '5'
                        "
                        @click="
                          goShop(
                            'lifeDetails',
                            item.shopInfo.id,
                            item.shopInfo.operationStatus == 1
                          )
                        "
                      >
                        <!-- <img  class="close-shop"  v-if="item.shopInfo.operationStatus !=1"  src="../../assets/img/closeShop.png" alt=""/> -->
                        <img
                          class="pic2"
                          style="border-radius: 0.26rem"
                          :src="item.shopInfo.imgUrl"
                          alt=""
                        />
                        <div class="right">
                          <div class="name">
                            {{ item.shopInfo.name }}
                            <!-- <span>经济型</span> -->
                          </div>
                          <div class="middle" style="flex: 1">
                            <van-rate
                              v-model="item.goodsScore"
                              allow-half
                              void-icon="star"
                              void-color="#eee"
                              size=".426667rem"
                              color="#ff5200"
                              readonly
                            />
                            <span>{{ item.goodsScore }}分</span>
                            <div>￥{{ item.paymentPrice.toFixed(2) }}/人</div>
                          </div>
                          <!-- <div class="location">正大广场 · 明珠广场</div> -->
                        </div>
                      </li>
                    </ul>
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div v-else-if="active == 7">
              <div
                class="item6"
                v-for="(item, index) in collect"
                :key="item.id"
              >
                <van-swipe-cell>
                  <template #right>
                    <van-button
                      square
                      text="取消收藏"
                      type="danger"
                      class="delete-button"
                      @click="cancelCollection(item, index, '1')"
                    />
                  </template>

                  <div style="heigth: 100%; display: flex; position: relative">
                    <ul class="shop-list">
                      <!-- <img  class="close-shop"   src="../../assets/img/closeShop.png" alt=""/> -->

                      <!-- 外卖店铺 -->

                      <li class="list-item1">
                        <img
                          class="pic2"
                          :src="item.logoUrl"
                          alt=""
                          @click="goFood(item.id, false)"
                        />
                        <div class="right">
                          <div class="name">{{ item.name }}</div>
                          <div class="box">
                            <span class="discount" v-if="item.list.length">
                              <span
                                v-for="li in item.list.filter(
                                  (item, index) => index < 3
                                )"
                                :key="li.id"
                                >{{ `${li.fullCash} - ${li.reduce}` }}</span
                              >
                            </span>
                          </div>

                          <div class="bottom">
                            <van-rate
                              v-model="item.mouthAppraise"
                              allow-half
                              void-icon="star-o"
                              void-color="transparent"
                              size=".426667rem"
                              color="#ff5200"
                              readonly
                            />
                            <span>{{ item.mouthAppraise - 0 }}分</span>
                            <div>月售{{ item.mouthSell }}份</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </van-swipe-cell>
              </div>
            </div>
            <div class="null" v-if="collect.length !== 0 && finished">
              已经到底了
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollection, delColl } from "../../api/collection/index";
import { getProduct, cancelTake } from "../../api/takeColletion";
export default {
  data() {
    return {
      isTakeShop: false,
      list: [
        {
          title: "商品",
          index: 1,
        },
        {
          title: "外卖",
          index: 7,
        },
        {
          title: "职位",
          index: 5,
        },
        {
          title: "简历",
          index: 6,
        },
        {
          title: "房源",
          index: 4,
        },
        {
          title: "车源",
          index: 3,
        },
        {
          title: "商城店铺",
          index: 2,
        },
        {
          title: "外卖店铺",
          index: 8,
        },
      ],
      active: 0,
      value: 3.5,
      page: 1,
      size: 20,
      collect: [],
      isShow: false,
      loading: false,
      finished: false,
      num: "",
      top: 0,
      sessionST: null,
    };
  },

  created() {
    this.sessionST = JSON.parse(sessionStorage.getItem("TOP")) ?? null;
    sessionStorage.removeItem("TOP");
    this.active = this.sessionST?.type - 0 || 0;
    this.finished = true;
    let id =
      this.$route.query.session || "dca16258-fe65-4c13-8f18-fa9195b95fad";
    this.$store.commit("setSession", id);

    this.changeIndex();
  },
  methods: {
    jump(type, id) {
      console.log(type, id);
      try {
        window.webkit.messageHandlers.jump.postMessage(
          JSON.stringify({ type: type, id: id })
        );
      } catch (error) {
        window.android.goRecruit(type, id);
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求

      this.page++;
      this.loading = true;
      this.getDate();
    },

    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    goMall(item) {
      if (item.platformId != null) {
        let params = {
          Id: item.goodsSpu.id,
          platformId: item.platformId,
          session: this.$store.state.session,
          type: "0",
        };
        this.$router.push({ name: "Goodsdetails", query: params });
        return;
      }

      let params = {
        id: item.relationId,
        isFirst: true,
        WoSession: this.$store.state.session,
      };

      this.$router.push({
        path: "detailsgoods",
        query: params,
      });
    },
    goFood(id, bloo, shopId) {
      if (!bloo) {
        this.$router.push({
          path: "/shopList",
          query: {
            shopId: id,
          },
        });
        return;
      }

      this.$router.push({
        path: "shoppingDetail",
        query: {
          id: id,
          shopId,
          session: this.$route.query.session,
        },
      });
    },
    goHouse(id, bloo) {
      if (bloo == "0") {
        this.$toast.fail("店铺或商品不存在");
        return;
      }

      this.$router.push({
        path: "listingDetails",
        query: {
          id: id,
          session: this.$route.query.session,
        },
      });
    },
    goCar(id, bloo) {
      if (bloo) {
        this.$toast.fail("店铺或商品不存在");
        return;
      }
      this.$router.push({
        path: "vehicleDetails",
        query: {
          id: id,
          session: this.$store.state.session,
        },
      });
    },
    goHotel(id) {
      this.$router.push({
        path: "/AhotelDetails",
        query: {
          start: parseInt(Date.now() / 1000),
          end: parseInt((Date.now() + 24 * 60 * 60 * 1000) / 1000),
          session: this.$store.state.token,
          roomNum: 1,
          personNum: 1,
          kidsNum: 0,
          latitude: "31.772639",
          longitude: "117.227962",
          type: "0",
          id: id,
        },
      });
    },
    getDate() {
      this.isShow = false;
      console.log(this.sessionST?.page * this.size || this.size);

      try {
        getCollection({
          current: this.page,
          size: this.sessionST?.page * this.size || this.size,
          type: this.list[this.active].index,
        }).then((res) => {
          this.loading = false;

          if (res.data.code === 0)
            this.collect = [...this.collect, ...res.data.data.records];
          console.log(this.collect, 454);
          if (this.collect.length === 0) {
            this.isShow = true;
          }

          if (res.data.data.records.length === this.size) {
            this.finished = false;

            // this.finished=false
          }
          if (res.data.data.records.length !== this.size) this.finished = true;
          if (this.sessionST) {
            this.$nextTick(() => {
              this.$refs.list.$el.scrollTo(0, this.sessionST.top - 0);
              this.page = this.sessionST.page - 0;
              this.sessionST = null;
              sessionStorage.removeItem("TOP");
            });
          }
        });
      } catch {
        //
      }
    },
    changeIndex() {
      this.page = 1;
      this.isShow = false;
      this.collect = [];
      this.finished = true;
      this.list[this.active].index < 7 && this.getDate();
      this.list[this.active].index > 6 && this.takeOut();
    },
    goShop(path, id, status) {
      console.log(999);
      if (status != "1") {
        this.$toast("店铺已暂停营业");
        return;
      }

      if (path == "lifeDetails") {
        this.$router.push({
          path: path,
          query: {
            Id: id,
          },
        });
        return;
      }
      this.$router.push({
        path: path,
        query: {
          id: id,
        },
      });
    },
    cancelColl(data, index) {
      let ids = [data];
      delColl(ids).then((res) => {
        if (res.data.code === 0) {
          this.collect.splice(index, 1);

          this.$toast.success("取消成功");
        }

        if (this.collect.length === 0) {
          this.isShow = true;
        }
      });
    },
    getTop() {
      this.top = this.$refs.list.scroller.scrollTop;
    },
    takeOut() {
      getProduct({
        current: this.page,
        size: this.sessionST?.page * this.size || this.size,
        status: this.list[this.active].index == 7 ? 2 : 1,
      }).then((res) => {
        this.collect = res.data.data.records;
        console.log(this.collect);
      });

      console.log(1111);
    },
    cancelCollection(item, index, type) {
      console.log(item);
      cancelTake(this.qs.stringify({ id: item.id, flag: type }))
        .then(() => {
          this.collect.splice(index, 1);
          this.$toast.success("取消成功");
        })
        .catch(() => {
          this.$toast.fail("取消失败");
        });
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },

  beforeRouteLeave(from, to, next) {
    console.log(this.$refs.list.$el.scrollTop);
    sessionStorage.setItem(
      "TOP",
      JSON.stringify({
        top: this.$refs.list.$el.scrollTop + "",
        type: this.active + "",
        page: this.page,
      })
    );
    next();
  },
};
</script>

<style lang="less" scoped>
/deep/.van-tabs--line .van-tabs__wrap {
  height: 1.173333rem !important;
}
.null {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.666667rem;
  padding-bottom: 1rem;
  color: #999;
}
.collection {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: PingFangSC-Semibold, PingFang SC;
  position: fixed;
  background-color: #f6f6f6;
  left: 0;
  top: 0;
  box-sizing: border-box;
  .head {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: white;
    width: 100%;
    width: 100%;
    padding: 0.266667rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 1.2rem;
    img {
      width: 0.666667rem;
      height: 0.64rem;
    }
    .title {
      // margin: auto;
      width: 100%;
      padding-right: 0.666667rem;
      text-align: center;
      font-size: 0.453333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }
  .main::-webkit-scrollbar {
    display: none;
  }
  .main {
    overflow: hidden;
    padding: 1.2rem 0 0;
    height: 100%;
    .van-tab__pane {
      padding: 0 0.426667rem;
    }
    .content {
      font-family: PingFangSC-Regular, PingFang SC;
      height: calc(100vh - 1.173333rem - 1.2rem);
      // height: 100%;
      width: 100%;

      box-sizing: border-box;
      overflow: hidden;

      .warp {
        margin-bottom: constant(safe-area-inset-bottom); //兼容IOS < 11.2
        margin-bottom: env(safe-area-inset-bottom); //兼容IOS > 11.2
      }
      .item {
        overflow: hidden;
        margin-bottom: 0.26rem;
        background-color: white;
        border-radius: 0.13rem;
        overflow: hidden;
        box-sizing: border-box;
        .good-img {
          width: 3.546667rem;
          height: 3.56rem;
          border-radius: 0.133333rem;
          margin-right: 0.266667rem;
        }
        .item-box {
          position: relative;
          box-sizing: border-box;
          .name {
            color: #333;
            font-size: 0.373333rem;
            width: 5.333333rem;
            height: 1.066667rem;
            margin-bottom: 0.16rem;
            img {
              vertical-align: middle;
              height: 0.426667rem;
              height: 0.426667rem;
            }
          }
          .price {
            color: #a80000;
            font-size: 0.48rem;
            margin-bottom: 0.24rem;
            span {
              font-size: 0.373333rem;
              color: #a80000;
            }
          }
          .shop {
            color: #999;
            font-size: 0.32rem;
          }
          .oraginprice {
            display: flex;
            align-items: center;
            color: #999;
            font-size: 0.32rem;
            span {
              text-decoration: line-through;
              margin: 0 0.053333rem 0 0.266667rem;
            }
          }
          .coupons {
            font-size: 0.32rem;
            display: flex;
            align-items: center;
            .coupons-itme {
              display: inline-block;
              width: 1.226667rem;
              height: 0.426667rem;
              background-image: url("../../assets/good_bg.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              color: white;
              div {
                width: 100%;
                height: 100%;
                transform: scale(0.83);
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            span {
              display: block;
              color: #ff0000;
              width: 1.866667rem;
              height: 0.48rem;
              text-align: center;
              background: #fef0f0;
              margin-left: 0.186667rem;
              div {
                width: 100%;
                height: 100%;
                transform: scale(0.83);
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .lose {
            width: 2.16rem;
            height: 1.893333rem;
            position: absolute;
            right: 0;
            bottom: 0.2rem;
          }
        }
      }
      .item1 {
        position: relative;
        background-color: white;
        border-radius: 0.13rem;
        margin-bottom: 0.26rem;
        overflow: hidden;
        box-sizing: border-box;

        .item1-box {
          display: flex;
          img {
            width: 2.453333rem;
            height: 2.453333rem;
            border-radius: 0.133333rem;
            margin-right: 0.4rem;
          }
          .right {
            width: 100%;
            .title {
              font-size: 0.426667rem;
              font-weight: 600;
              color: #333;
              margin-bottom: 0.24rem;
            }
            .sell-num {
              font-size: 0.32rem;
              margin-bottom: 0.133333rem;
            }
            .price {
              display: flex;
              align-items: center;
              align-items: flex-end;
              div {
                color: #a80000;
                display: flex;
                align-items: flex-end;
                div {
                  font-size: 0.266667rem;
                  span {
                    font-size: 0.426667rem;
                  }
                }
              }
              > span {
                margin-left: 0.133333rem;
                color: #999;
                text-decoration: line-through;
                font-size: 0.32rem;
              }
            }
          }
        }
        .name {
          padding-bottom: 0.1333rem;

          img {
            width: 0.56rem;
            height: 0.56rem;
            vertical-align: middle;
            margin-left: 0.2667rem;
          }
          span {
            font-size: 0.32rem;
            color: #333;
          }
        }
        .lose {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 2.16rem;
          height: 1.893333rem;
        }
      }
      .item2 {
        position: relative;
        background-color: white;
        border-radius: 0.13rem;
        margin-bottom: 0.26rem;
        box-sizing: border-box;
        overflow: hidden;
        .position {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.266667rem;
          font-size: 0.426667rem;
          font-weight: 600;
          .welfare {
            margin-right: 0.293333rem;
            color: #ff5200;
          }
        }
        .ask {
          flex: 1;
          display: flex;
          margin-bottom: 0.266667rem;
          .lable {
            margin-right: 0.26rem;
            height: 0.613333rem;
            padding: 0 0.213333rem;
            background-color: #eee;
            color: #333;
            font-size: 0.32rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .company-info {
          display: flex;
          p {
            height: 0.32rem;
            width: 1px;
            background-color: #999;
            margin-right: 0.26rem;
          }
          div {
            display: flex;
            align-items: center;
            margin-right: 0.266667rem;
            font-size: 0.32rem;
          }
        }
        .lose {
          width: 2.16rem;
          height: 1.893333rem;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .item3 {
        height: 2.773333rem;
        background-color: white;
        border-radius: 0.13rem;
        box-sizing: border-box;
        margin-bottom: 0.266667rem;
        overflow: hidden;
        img {
          overflow: hidden;
          width: 2.24rem;
          height: 2.24rem;
          border-radius: 0.133333rem;
          margin-right: 0.266667rem;
        }
        .persion-info {
          height: 100%;
          flex: 1;
          .name {
            font-size: 0.426667rem;
            font-weight: 600;
            margin-bottom: 0.56rem;
          }
          .lables {
            display: flex;
            align-items: center;
            font-size: 0.32rem;
            margin-bottom: 0.133333rem;
            .lable {
              height: 0.32rem;
              border-right: 0.013333rem solid #ccc;
              color: #999;
              margin-right: 0.133333rem;
              padding-right: 0.133333rem;
              display: flex;
              align-items: center;
            }
            .lable:nth-last-of-type(1) {
              margin: none;
              padding-right: none;
              border: none;
            }
          }
          .bottom {
            display: flex;
            font-size: 0.32rem;
            div {
              margin-right: 0.266667rem;
            }
          }
        }
      }

      .item4,
      .item5 {
        width: 100%;
        background-color: white;
        border-radius: 0.13rem;
        position: relative;
        margin-bottom: 0.26rem;
        overflow: hidden;
        box-sizing: border-box;

        img {
          width: 2.986667rem;
          height: 2.453333rem;
          margin-right: 0.266667rem;
          border-radius: 0.133333rem;
          overflow: hidden;
        }
        .house-box {
          display: flex;
          flex-direction: column;
          flex: 1;

          .desc {
            font-size: 0.373333rem;
            font-weight: 600;
            margin-bottom: 0.106667rem;
          }
          .lables {
            flex: 1;
            display: flex;
            align-items: center;
            color: #999;
            height: 100%;
            font-size: 0.32rem;
            flex: 1;

            .lable {
              height: 0.3rem;
              margin-right: 0.266667rem;
              padding-right: 0.266667rem;
              border-right: 1px solid #999;
              display: flex;
              align-items: center;
            }
            .lable:nth-last-of-type(1) {
              border: none;
              padding: none;
              margin: none;
            }
            sup {
              font-size: 12px;
              display: block;
              transform: scale(0.83);
            }
          }
          .bottom {
            display: flex;
            margin-top: 0.133333rem;
            align-items: center;

            .price {
              font-size: 0.453333rem;
              font-weight: 600;
              color: #a80000;
              margin-right: 0.266667rem;
            }
            .labels {
              flex: 1;
              color: #999;
              display: flex;
              align-items: center;
              height: 0.453333rem;
              font-size: 0.32rem;

              .lable {
                box-sizing: border-box;
                position: relative;

                span {
                  display: inline-block;
                  font-size: 0.32rem;
                  position: absolute;
                  top: -0.17rem;
                  right: -0.1rem;
                  transform: scale(0.7);
                }
              }

              p {
                height: 0.3rem;
                background-color: #999;
                width: 1px;
                margin: 0 0.2rem;
              }
              .lable:nth-last-of-type(1) {
                margin: 0;
                padding: 0;
                border: none;
              }
            }
          }
          .lose {
            width: 2.16rem;
            height: 1.893333rem;
            position: absolute;
            bottom: 0.266667rem;
            right: 0;
            margin: 0;
          }
        }
      }
      .item6 {
        background-color: white;
        border-radius: 0.13rem;
        margin-bottom: 0.26rem;
        overflow: hidden;
        .shop-list {
          width: 100%;
        }
        .close-shop {
          position: absolute;
          right: 0;
          top: 0;
          width: 1.546667rem;
          height: 1.52rem;
          z-index: 100;
        }
        .list-item {
          display: flex;
          position: relative;
          border-radius: 0.13rem;
          margin-bottom: 0.26rem;
          padding: 0.266667rem;
          overflow: hidden;

          .pic2 {
            width: 2.426667rem;
            height: 2.426667rem;
            margin-right: 0.266667rem;
            border-radius: 0.13rem;
          }

          .right {
            height: 100%;
            flex: 1;
            height: 2.426667rem;
            .name {
              font-size: 0.4rem;
              font-weight: 600;
              margin-bottom: 0.133333rem;
            }
            .middle {
              height: 0.8rem;
              .desc {
                float: left;
                height: 0.533333rem;
                // width: 1.973333rem;
                background: #f1f1f1;
                border-radius: 0.266667rem;
                margin-right: 0.266667rem;
                padding: 0 0.133333rem;
                p {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 0.32rem;
                  transform: scale(0.83);
                  color: #333;
                }
              }
              div {
                float: left;
                font-size: 0.32rem;
                color: #999;
                height: 100%;
              }
            }
            .img-list::-webkit-scrollbar {
              display: none;
            }
            .img-list {
              display: flex;
              width: 100%;
              overflow: auto;
              img {
                width: 0.906667rem;
                height: 0.906667rem;
                border-radius: 0.133333rem;
                margin-right: 0.13rem;
              }
            }
          }
        }
        .list-item1 {
          padding: 0.266667rem;
          margin: 0.266667rem 0;
          display: flex;
          position: relative;
          background-color: white;
          border-radius: 0.13rem;
          overflow: hidden;

          .pic2 {
            width: 2.426667rem;
            height: 2.426667rem;
            border-radius: 0.133333rem;
            margin-right: 0.266667rem;
          }

          .right {
            height: 100%;
            width: 100%;
            flex: 1;
            .name {
              font-size: 0.4rem;
              font-weight: 600;
              margin-bottom: 0.72rem;
            }
            .box {
              height: 0.4rem;
              float: left;
              .discount {
                display: inline-block;
                height: 0.4rem;
                border: 0.026667rem solid #ff5200;
                border-radius: 0.053333rem;
                color: #ff5200;
                display: flex;
                align-items: center;
                margin-bottom: 0.266667rem;
                span {
                  display: inline-block;
                  padding: 0 0.133333rem;
                  text-align: center;
                  font-size: 0.32rem;
                  transform: scale(0.83);
                  border-right: 1px solid #ff5200;
                  height: 0.4rem;
                }
                span:nth-last-of-type(1) {
                  border-right: none;
                }
              }
            }

            .bottom {
              margin-top: 0.333333rem;
              float: left;
              display: flex;
              align-items: center;
              width: 100%;
              span {
                color: #ff5200;
                margin-left: 0.133333rem;
                margin-right: 0.266667rem;
              }
              div {
                color: #999;
                font-size: 0.32rem;
              }
            }
          }
        }
        .list-item2 {
          padding: 0.266667rem;

          flex: 1;
          position: relative;

          background-color: white;
          border-radius: 0.13rem;
          display: flex;
          overflow: hidden;

          .close-shop {
            width: 1.546667rem;
            height: 1.52rem;
            position: absolute;
            top: 0;
            right: 0;
          }
          .pic2 {
            width: 2.426667rem;
            height: 2.426667rem;
            margin-right: 0.266667rem;
            border-radius: 0.13rem;
          }
          .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
              font-size: 0.4rem;
              font-weight: 600;
              margin-bottom: 0.133333rem;
              span {
                color: #ccc;
                font-size: 0.32rem;
              }
            }
            .location {
              font-size: 0.32rem;
              color: #999;
              margin-bottom: 0.186667rem;
              flex: 1;
            }
            .price {
              display: flex;
              font-size: 0.32rem;
              color: #999;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              span {
                text-decoration: line-through;
              }
              div {
                color: #a80000;
                font-weight: 600;
                margin-right: 0.08rem;
                margin-left: 0.266667rem;
                span {
                  text-decoration: none;
                  font-size: 0.4rem;
                }
              }
            }
          }
        }
        .list-item3 {
          padding: 0.266667rem;
          position: relative;
          padding: 0.26rem;
          background-color: white;
          border-radius: 0.13rem;
          display: flex;
          margin-bottom: 0.266667rem;
          overflow: hidden;

          .pic2 {
            width: 2.426667rem;
            height: 2.426667rem;
            margin-right: 0.266667rem;
            border-radius: 0.13rem;
          }
          .right {
            height: 2.426667rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            .name {
              font-size: 0.4rem;
              font-weight: 600;
              margin-bottom: 0.133333rem;
              span {
                color: #ccc;
                font-size: 0.32rem;
              }
            }
            .middle {
              flex: 1;
              display: flex;
              align-items: center;
              margin-bottom: 0.133333rem;
              div {
                margin-left: 0.133333rem;
                color: #999;
                font-size: 0.32rem;
              }
            }
            .location {
              font-size: 0.32rem;
              color: #999;
              margin-bottom: 0.186667rem;
            }
          }
        }
      }
    }
  }
}
.empty {
  width: 4.026667rem;
  height: 5.253333rem;

  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}
.goods-card {
  margin: 0;
  background-color: white;
}

.delete-button {
  margin-left: 0.0267rem;
  height: 100%;
  border-radius: 0 0.13rem 0.13rem 0;
}
.van-swipe-cell__wrapper {
  display: flex;
  align-items: center;
}
/deep/ .van-tabs {
  overflow: hidden;
}

/deep/ .van-list {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 0.26rem;
}
.list-box {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.van-swipe-cell {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.13rem;
  overflow: hidden;
}
/deep/ .van-list::-webkit-scrollbar {
  display: none;
}
</style>
