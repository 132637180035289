import {fetchGet , fetchPost} from '../../router/https'


export function getProduct(data){ 

    return fetchGet('/takeout//user/favorite/page',data)

}

 export function cancelTake(data){ 
    return fetchPost('/takeout/user/favorite/delete' ,data )
}